.container {

    padding: 12px 10px 12px 30px;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }

    @media screen and (min-width: 1200px) {
        position: fixed;
        bottom: 121px;
        width: 220px;
        height: 204px;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    .copyButton {
        margin-right: 20px;
        margin-top: 5px;
        background-color: transparent;
        text-decoration: none;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 700;
        border: 1px solid white;
        color: white;
        line-height: 16px;

        @media screen and (max-width: 1200px) {
            width: calc(100% - 20px);
            color: #0060ae;
            border-color: #0060ae;
            padding: 10px;
        }
    }
}
