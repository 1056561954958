.upload {
  .area {
    align-items: center;
    align-self: stretch;
    border: 1px dashed;
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    position: relative;
    width: 100%;
    &:hover {
      background: rgba(55, 73, 229, 0.05);
      cursor: pointer;
    }
  }
  .upload-input {
    position: absolute;
    color: transparent;
  }

  .frame-help {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .frame-help-text {
    align-self: stretch;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  .supported-formats {
    align-self: stretch;
    position: relative;
    text-align: center;
  }
  .icon-upload {
    height: 32px !important;
    position: relative !important;
    width: 32px !important;
  }
  .file {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: max-content;
    max-width: 100%;
    padding: 0 8px;
    .text {
      flex: 1;
      position: relative;
    }
  }
}
