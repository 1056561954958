.videoCuter {
  width: 500px;
  max-width: 100%;
  height: 330px;
  max-height: 58vw;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  .video {
    width: 848px;
    height: 464px;
    max-height: 80vw;
  }
}
.videoCuterMoved {
  width: 570px;
  max-height: 50vw;
  .video {
    max-height: 69vw;
  }
}

.popUpContainer {
  margin: -30px;
  font-size: 24px;
  padding-top: 46px;
}

.successPayment {
  padding-top: 0;
  .videoCuter {
    border-radius: 16px;
  }
}

.title {
  top: 22px;
}
