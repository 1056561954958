/*.my-tooltip-container {*/
/*    width: 150px;*/
/*    position: relative;*/
/*    !*max-width: 100%;*!*/
/*}*/

/*.my-tooltip {*/
/*    position: absolute;*/

/*    background-color: white;*/
/*    width: 100%;*/
/*    !*width: 160px;*!*/
/*    !*min-width: 100px;*!*/
/*    !*max-width: 160px;*!*/
/*    border-radius: 8px;*/

/*    padding: 10px;*/

/*    text-align: center;*/
/*    box-shadow: 0 4px 24px rgba(23, 23, 37, 0.12);*/
/*    visibility: hidden;*/
/*    transition: all 200ms ease-in-out;*/
/*    opacity: 0;*/

/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 10px;*/
/*    line-height: 14px;*/
/*    color: #000000;*/
/*}*/

/*.my-tooltip-top {*/
/*    left: 50%;*/
/*    margin-left: -75px;*/
/*    bottom: calc(100% + 4px);*/
/*}*/

/*.my-tooltip-left {*/
/*    left: 0;*/
/*}*/

/*.my-tooltip-right {*/
/*    right: 0;*/
/*}*/

/*.my-tooltip-top .my-tooltip-arrow {*/
/*    top: calc(100% - 4px);*/
/*    left: calc(50% - 4px);*/
/*}*/

/*.my-tooltip-left .my-tooltip-arrow {*/
/*    top: calc(100% - 4px);*/
/*    left: calc(10% - 4px);*/
/*}*/

/*.my-tooltip-right .my-tooltip-arrow {*/
/*    top: calc(100% - 4px);*/
/*    right: calc(10% - 4px);*/
/*}*/

/*.my-tooltip>.my-tooltip-arrow {*/
/*    width: 8px;*/
/*    height: 8px;*/
/*    position: absolute;*/
/*    background-color: white;*/
/*    transform: rotate(45deg);*/
/*}*/

/**:hover>.my-tooltip-container>.my-tooltip {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*}*/

.my-tooltip {
    background-color: #FFFFFF!important;
    box-shadow: 0 4px 24px rgba(23, 23, 37, 0.12)!important;
    border-radius: 8px!important;
    opacity: 1!important;
    color: #000000!important;
    max-width: 250px;

    font-weight: 400!important;
    font-size: 10px!important;
    line-height: 14px!important;

    word-break: break-all;
    /* identical to box height, or 140% */

    pointer-events: unset!important;
}

.my-tooltip.sent-parcel {
    font-size: 12px!important;
    max-width: 319px;
}

.my-tooltip.unidentified-parcel {
    font-size: 12px!important;
    line-height: 16px!important;
}

.my-tooltip.promo-codes {
    left: 10px !important;
    font-size: 12px!important;
    line-height: 16px!important;
}

.my-tooltip:after {
    border-top-color: #FFFFFF!important;
}

.my-tooltip.place-left:after {
    border-left-color: #FFFFFF!important;
}

.my-tooltip.place-bottom:after {
    border-bottom-color: #FFFFFF!important;
}

.my-tooltip.place-right:after {
    border-right-color: #FFFFFF!important;
}
