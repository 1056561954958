.phoneVerifyContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.timerButton {
    min-width: 120px;
    gap: 10px;

    &[data-loading="true"] {
        color: #0060AE !important;
    }

    &:disabled {
        color: #0060AE !important;
    }
}

.timerSpinner {
    width: 24px;
    height: 24px;
}
