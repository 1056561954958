.modal-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    transition: all 300ms ease-in-out;
    z-index: 2;
}

.modal-form-hide {
    opacity: 0;
    z-index: -1;
}

.modal-form-center {
    max-width: 100%;
    max-height: 100vh;
    min-width: 360px;
    min-height: 170px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-form-content {
    height: min-content;
    max-height: calc(100% - 30px);
    background-color: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(23, 23, 37, 0.12);
    border-radius: 16px;
}
.modal-form-content-profile {
    min-width: 470px;
}

.modal-form-content form {
}

.modal-form-content .warn-title {
    max-width: 420px;
    padding: 0px 18px 0 18px;
    color: brown;
}

.modal-form-header {
}

.modal-form-header:nth-child(1) {
    padding: 18px 20px 0 20px;
    line-height: 26px;
}

.modal-form-close {
    cursor: pointer;
    transition: all 300ms linear;
    border: 1px #FFFFFF solid;
}

.modal-form-close:hover {
    border: 1px #8493A8 solid;
}

.modal-form-button {
    margin-bottom: 15px;
    margin-left: 15px;
}

.modal-form-body {
    height: calc(100% - 0px);
    max-width: 1400px;
    padding: 30px;
}

.modal-form-body-with-title {
    height: calc(100% - 42px);
}

.modal-form-body form {
    height: 100%;
    overflow: auto;
}

.modal-form-body form table {
    max-height: calc(100vh - 192px);
    overflow-y: auto;
}

.firtersList {
    height: calc(100vh - 500px);
    padding-bottom: 1rem;
}

@media screen and (max-width: 768px){
    .modal-form {
        padding: 0 11px;
    }

    .modal-form-fullscreen .modal-form-content {
        min-width: 100%;
        height: fit-content;
        max-height: 100%;
    }

    .modal-form-button {
        margin-bottom: 15px;
        margin-left: 0;
    }
	.pop-up-for-try>img {
		display: none;
	}
    .modal-form-content-profile {
        min-width: 270px;
    }
}
@media (max-width: 998px) {
    .minW-M-D {
        min-width: 100%;
    }
}
@media (min-width: 999px) {
    .minW-M-D {
        width: 620px;
    }
}
