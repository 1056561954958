.my-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.my-alert-content {
    padding: 18px;
    width: 380px;
    min-height: 170px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(23, 23, 37, 0.12);
    border-radius: 16px;
}

.my-alert-close {
    cursor: pointer;
    transition: all 300ms linear;
    border: 1px #FFFFFF solid;
}

.my-alert-close:hover {
    border: 1px #8493A8 solid;
}
