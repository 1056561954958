.container {
    padding: 12px 10px 12px 30px;
    text-decoration-line: none;

    @media screen and (min-width: 1200px) {
        &:hover {
            color: white;
        }
    }
}
