
.copy {
    justify-content: flex-start;
    div:first-of-type {
        color: #0060AE;
        font-weight: 700;
        width: max-content !important;
        max-width: 100%;
        padding: 0 5px;
    }
}
.children {
    color: #0060AE;
    font-weight: 700;
    width: max-content !important;
    max-width: 100%;
    padding: 0 5px;
}