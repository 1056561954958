
@import './_variables.scss';

.tabs {
    display: flex;
    width: auto;
    margin-bottom: 25px;
    background: transparent;
    font-weight: 700;
    color: $col-my-text-primary;

    .tab {
        min-height: 32px;
        padding: 0 18px;
        font-weight: 600;

        cursor: pointer;
        line-height: 32px;
        text-align: center;
        background-color: $col-not-active-item;

        &.select {
            border-top: 1px solid #b2c0ce;
            border-right: 1px solid #b2c0ce;
            border-left: 1px solid #b2c0ce;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: #fafafa;
        }
    }
}

.select-var-item-checkbox input {
  margin-top: 2px;
}

.poll {
  .check-input input {
    margin-top: 2px;
  }
  .questions {
   
  }
}
.poll-select {
  option {
    height: 18px;
    background-color: #77b1fd;
    color: #014bad;
    border: 1px solid #014bad;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.poll-form-content {
	.rounded-0 {
		border-radius: 0 !important;
	} 
  .bgcol-figma-text-primary {
    background-color: $col-figma-primary !important;
    &:hover {
      border-color: $col-figma-primary !important;
    }
  }
}

.poll-form {
  width: 800px;
  max-width: 100%;
  max-height: 100%;

  .poll-question {
    text-align: center;
  }
}

.pop-up-for-try {
  width: 996px;
  max-width: 100%;
  height: 476px;
  button {
    border-radius: 0 !important;
  }
}

.btn-outline-danger {
    border-radius: 20px;
    background: #FFFFFF;

    &:hover, &:active, &:focus {
        border-color: $col-my-outline-primary;
        background: #FFFFFF;
        color: $col-my-outline-primary;
        box-shadow: unset !important;
    }
}

.blue-scroll {
  scrollbar-color: $col-figma-primary transparent;
}

.thin-scroll {
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
  
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $col-grey-disable;
      border-radius: 16px;
      border: 5px solid $col-my-text-primary;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
}

@media screen and (max-width: 1199px) {
  .poll-form {
    .poll-question {
      text-align: left;
    }
  }
}