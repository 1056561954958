@import "./app.css";
@import "./spinner.css";
@import "./modal.css";
@import "./alert.css";
@import "./tooltip.css";

.lang a {
    text-decoration-line: none;
    padding-right: 3px;
}

.lang a:first-child {
    position: relative;
    margin-right: 5px;
    padding-right: 5px;
}

.lang a:first-child::after {
    content: "";
    position: absolute;
    right: 0;
    top: calc(50% - 6px);
    width: 1px;
    height: 12px;
    background: #dfdfdf;
}

.lang a span {
    color: #72a2c9;
}

.lang a span:hover {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.active-lang {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form-placeholder::placeholder {
    color: #8493A8 !important;
}

.btn-primary {
    text-align: center !important;
    padding: 8px 18px !important;
    background: #0060AE !important;
    border-radius: 20px !important;

    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
}

.btn-primary:hover {
    background: #EC1944 !important;
    border-color: #EC1944 !important;
}

.btn-primary:focus {
    box-shadow: unset !important;
}

.btn-primary:active {
    background: #B60327 !important;
}

.btn-primary:disabled {
    background: #DFDFDF !important;
    border-color: #DFDFDF !important;
}
#page-search {
    min-width: 100% !important;
    text-transform: uppercase !important;
}

.w-218 {
    width: 218px !important;
}
.w-170 {
    width: 170px !important;
}
.mw-100pr {
    min-width: 100% !important;
    margin: 0;
}
.btn-outline-primary {
    /*min-width: 100% !important;*/
    /*margin-right: 10px;*/
    text-align: center !important;
    padding: 8px 18px !important;
    background: #FFFFFF !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
}
@media (max-width: 360px) {
    .mt-15px-max-w-360 {
        margin-top: -15px;
    }
}
@media (max-width: 360px) and (min-width: 244px) {
    /* Small mobile version padding */
    .select-all-btn-max-w-360 {
        min-width: 100%;
        min-height: 100%;
    }
    .smbMinWidth {
        width: 49%;
    }
    .smbMinWidthNewParcel {
        margin-left: 0 !important;
        width: 49% !important;
    }
}
@media (max-width: 243px) {
    .select-all-btn-max-w-360 {
        min-width: 100%;
        min-height: 88px !important;
    }
    .smbMinWidthNewParcel {
        margin-left: 0 !important;
        width: 49% !important;
    }
}
.btn-outline-primary:hover {
    border-color: #EC1944 !important;
    color: #EC1944 !important;
}

.btn-outline-primary:focus {
    box-shadow: unset !important;
}

.btn-outline-primary:active {
    color: #B60327 !important;
}

.btn-outline-primary:disabled {
    border-color: #DFDFDF !important;
    color: #DFDFDF !important;
}

.form-input {
    padding: 6px 18px !important;
    border-radius: 20px !important;
    /*border: 1px solid #CCCCCC!important;*/
    line-height: 24px !important;
    font-size: 14px !important;
    color: #333333 !important;
}

.form-input:focus {
    border: 1px solid #169CE1 !important;
    box-shadow: unset !important;
    color: #000000 !important;
}

.form-input:disabled {
    background-color: #F5F5F5 !important;
    border: 1px solid #DFDFDF !important;
    color: #C4C4C4 !important;
}

.form-control.is-valid, .form-control:valid, .form-control:invalid {
    background-image: unset !important;
}

.form-control.is-invalid {
    background-image: unset !important;
}

.text-login-page {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #0060AE;
}

.login-form-top {
    position: relative;
}

@media screen and (min-width: 768px) {
    .login-form-top {
        top: -111px;
    }
}

.text-500 {
    font-weight: 500 !important;
}

.text-bold {
    font-weight: bold !important;
}

.z-index-unset {
    z-index: unset !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.z-index-3 {
    z-index: 3 !important;
}

.z-index-4 {
    z-index: 4 !important;
}

.z-index-5 {
    z-index: 5 !important;
}

.my-text-white {
    color: #FFFFFF;
}

.my-text-primary {
    color: #0060AE;
}

.my-text-danger {
    color: #EC1944;
}

.my-text-success {
    color: #2da771;
}

.background-primary {
    background-color: #0060AE;
}

.background-danger {
    background-color: #EC1944;
}

.background-wрite {
    background-color: #FFF;
}

.ml-05 {
    margin-left: 0.25rem;;
}

.ml-1 {
    margin-left: 0.5rem;;
}

.ml-2 {
    margin-left: 0.75rem;;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.25rem;
}
.mli-4 {
    margin-left: 1.25rem !important;
}

.ml-5 {
    margin-left: 1.5rem;
}

.mr-05 {
    margin-right: 0.25rem;;
}

.mr-1 {
    margin-right: 0.5rem;;
}

.mr-2 {
    margin-right: 0.75rem;;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.25rem;
}

.mr-5 {
    margin-right: 1.5rem;
}

.mt-5 {
    margin-top: 5px;
}

.mt-9 {
    margin-top: 9px;
}

.mt-children-input-0 {
    input {
        margin-top: 0;
    }
}

.mtb-9 {
    margin-top: 9px;
    margin-bottom: 9px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-08vw {
    margin-left: 0.8vw;
}

.mr-08vw {
    margin-right: 0.8vw;
}

.py-4px {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.p-4px-5px {
    padding: 4px 5px !important;
}

.p-4px-12px {
    padding: 4px 12px !important;
}

.p-20 {
    padding: 20px !important;
}

.pl-15 {
    padding-left: 15px;
}

.pl-25i {
    padding-left: 25px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px;
}

.w-max-content {
    width: max-content;
}

.cursor-def {
    cursor: default;
}

.w-0 {
    width: 0;
}

.lh-0 {
    line-height: 0px !important;
}

.lh-20px {
    line-height: 20px;
}

.hide-small-menu {
    left: -300px !important;
}

.text-left {
    text-align: left;
}

.text-left-important {
    text-align: left !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.background-small-menu {
    z-index: -1;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(114, 111, 111, 0.47);
    /*transition: all 750ms ease-in-out;*/
}

.divider {
    background-color: #DFDFDF;
}

.list-page-menu {
    transition: all 0.5s ease-in-out;
}

.opacity-1 {
    opacity: 1 !important;
}

.opacity-0 {
    opacity: 0;
}

.visibility-visible {
    visibility: visible;
}

.visibility-hidden {
    visibility: hidden;
}

.aside-large #logo-large {

    /* Meestcn logo */
    /*padding: 20px;*/
    /*margin-bottom: 5px;*/

    /* New year logo */
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    height: 70px;
}

.nav-menu {
    margin: 0 !important;
    list-style-type: none;
    padding: 0;
}

.nav-item {
    padding: 8px 10px 8px 35px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;

    transition: background-color 100ms ease-in;
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.active-nav-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.my-d-none {
    display: none !important;
}

.my-d-flex {
    display: flex !important;
}

.btn-outline-primary-no-border {
    color: #0060AE !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    border: none !important;
}

.btn-outline-primary-no-border.disablehover:hover {
    color: #0060AE !important;
    cursor: default;
}

.btn-outline-primary-no-border:hover {
    color: #EC1944 !important;
}

.btn-outline-primary-no-border:active {
    color: #B60327 !important;
    border: none !important;
}

.btn-outline-primary-no-border:focus {
    border: none !important;
    box-shadow: none !important;
}

.btn-outline-white-no-border {
    color: white !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    border: none !important;
}

.btn-outline-white-no-border:hover {
    color: #EC1944 !important;
}

.btn-outline-white-no-border:active {
    color: #B60327 !important;
    border: none !important;
}

.btn-outline-white-no-border:focus {
    border: none !important;
    box-shadow: none !important;
}

.container-page {
    background-color: #FFFFFF;
    border-radius: 8px;

    overflow: hidden;
}

.container-page > .page-actions {
    padding: 10px;
}

.container-page .page-header {
    background-color: #ECEFF4;
    /*padding: 10px 10px;*/
    color: #8493A8;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    padding-right: 16px;
}

.table-row-item:nth-child(even) {
    background-color: #d4e2ec;
}

main > div {
    padding: 20px;
    padding-bottom: 10px;
}

main[data-notificationbar=false] > div {
    height: calc(100% - 104px);
}

main[data-footerisopen=true] > div {
    height: calc(100% - 160px);
}

main[data-footerisopen=true][data-notificationbar=true] > div {
    height: calc(100% - 226px);
}

main[data-footerisopen=false][data-notificationbar=true] > div {
    height: calc(100% - 160px);
}

.page-body {
    height: calc(100% - 106px);
    overflow-y: scroll;
}

.promo-codes .page-body {
    height: calc(100% - 30px);
}

.height-sub-tabs {
    height: calc(100% - 90px);
}

.max-height-sub-tabs {
    max-height: calc(100% - 58px);
}

.height-list-filter-list {
    height: calc(100% - 102px);
}

.height-list-filter {
    height: calc(100% - 206px);
    /* height: calc(100% - 92px); */
}

.height-list-head {
    height: calc(100% - 44px);
}

.infinite-scroll-component {
    overflow-x: hidden !important;
}

.text-minimise {
    white-space: nowrap;
    /*white-space: pre;*/
    overflow: hidden;
    text-overflow: ellipsis;
}

.two-line-text {
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ws-nowrap {
    white-space: nowrap;
}

.wb-normal {
    word-break: normal;
}

.minw-100 {
    min-width: 100px;
}

.minw-105 {
    min-width: 105px;
}

.w-100px {
    width: 100px;
}

.minw-150 {
    min-width: 150px;
}

.mw-100 {
    max-width: 100%;
}


.table-item-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #000000;
}

.table-item-selected {
    background: #8FD9FF !important;
}

.flex-0-1-50 {
    flex: 0 1 50%;
}

.my-col-1 {
    flex: 0 0 auto;
    width: 4.16666667% !important;
}

.my-col-2 {
    flex: 0 0 auto;
    width: 8.3333333% !important;
}

.my-col-3 {
    flex: 0 0 auto;
    width: 12.5% !important;
}

.my-col-4 {
    flex: 0 0 auto;
    width: 16.6666667% !important;
}

.my-col-5 {
    flex: 0 0 auto;
    width: 20.8333333% !important;
}

.my-col-6 {
    flex: 0 0 auto;
    width: 25% !important;
}

.my-col-7 {
    flex: 0 0 auto;
    width: 29.1666667% !important;
}

.my-col-8 {
    flex: 0 0 auto;
    width: 33.3333334% !important;
}

.my-col-9 {
    flex: 0 0 auto;
    width: 37.5% !important;
}

.my-col-10 {
    flex: 0 0 auto;
    width: 41.6666667% !important;
}

.my-col-11 {
    flex: 0 0 auto;
    width: 45.8333334% !important;
}

.my-col-12 {
    flex: 0 0 auto;
    width: 50% !important;
}

.my-col-13 {
    flex: 0 0 auto;
    width: 54.16666667% !important;
}

.my-col-14 {
    flex: 0 0 auto;
    width: 58.3333334% !important;
}

.my-col-15 {
    flex: 0 0 auto;
    width: 62.5000001% !important;
}

.my-col-16 {
    flex: 0 0 auto;
    width: 66.6666667% !important;
}

.my-col-17 {
    flex: 0 0 auto;
    width: 70.8333334% !important;
}

.my-col-18 {
    flex: 0 0 auto;
    width: 75.0000001% !important;
}

.my-col-19 {
    flex: 0 0 auto;
    width: 79.1666667% !important;
}

.my-col-20 {
    flex: 0 0 auto;
    width: 83.3333334% !important;
}

.my-col-21 {
    flex: 0 0 auto;
    width: 87.5000001% !important;
}

.my-col-22 {
    flex: 0 0 auto;
    width: 91.6666667% !important;
}

.my-col-23 {
    flex: 0 0 auto;
    width: 95.8333334% !important;
}

.my-col-24 {
    flex: 0 0 auto;
    width: 100% !important;
}

.flex-0-0-0 {
    flex: 0 0 0;
}

.flex-1-0 {
    flex: 1 0 auto;
}

.my-select {
    /*background: #FFFFFF!important;*/
    height: 42px;
    border: 1px solid #CCCCCC !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
}

.my-select:focus {
    box-shadow: unset !important;
}

.clear-search-btn {
    position: absolute;
    right: 7px;
    top: 7px;
    width: 25px;

    transform: rotate(45deg);
    visibility: hidden;

    transition: all 300ms ease-in-out;
}

.top-3px {
    top: 3px;
}

.top-5px {
    top: 5px;
}

.top-7px {
    top: 7px;
}

.btn-show-hide-pwd {
    position: absolute;
    right: 38px;
    top: 7px;
    width: 25px;

    visibility: hidden;

    transition: all 300ms ease-in-out;
}

.btn-show-hide-pwd i {
    font-size: 18px;
    line-height: 26px;
    color: #0060AE;
}

.btn-show-hide-pwd-show {
    visibility: visible;
}

.clear-btn-left-s {
    width: 20px;
    transition: all 300ms ease-in-out;
}

.clear-search-btn-show {
    visibility: visible;
    transform: rotate(90deg);
}

.padding-when-clear-search-button-viewed {
    padding-right: 32px !important;
}

.input-exclamation {
    position: absolute;
    right: 32px;
    top: 1px;
    background: #fff;
    padding: 7px;
}

.padding-when-exclamation-viewed {
    padding-right: 64px !important;
}

.input-loader {
    position: absolute;
    top: 7px;
    right: 12px;
}

.input-loader .spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 3px;
}

.add-new-td {
    background: #FAFAFA;
    padding-bottom: 25px !important;
    /*border: 1px solid #DFDFDF;*/
    /*border-radius: 10px 0 0 0;*/
    /*display: block;*/
}

.add-new-td-left-top {
    border-top: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-left-bottom {
    border-bottom: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-right-top {
    border-top: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-right-bottom {
    border-bottom: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-left {
    border-left: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-right {
    border-right: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-top {
    border-top: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

.add-new-td-bottom {
    border-bottom: 1px solid #DFDFDF;
    /*border-radius: 10px 0 0 0;*/
}

#new-parcel-form td > div {
    display: inline-block;
    width: 100%;
}

#new-parcel-form td {
    padding: 0;
}

#new-parcel-form tr {
    background: #FAFAFA;
}

.add-new-td-border-radius-top {
    border-radius: 10px 0 0 0;
}

.add-new-td-border-radius-left {
    border-radius: 0 0 0 10px;
}

.add-new-td-border-radius-bottom {
    border-radius: 0 0 10px 0;
}

.add-new-td-border-radius-right {
    border-radius: 0 10px 0 0;
}

.react-datepicker-wrapper {
    /*width: unset!important;*/
}

.react-datepicker__input-container input {
    width: 100%;
    height: 42px;
}

.text-style-1 {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
}

.fs-12px {
    font-size: 12px;
}

.fs-12px-i {
    font-size: 12px !important;
}

#my-date-picker span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-right: 10px;
}
#my-date-picker span.lh-0 {
    line-height: 0px !important;
}


.react-datepicker__input-container input {
    background: #FFFFFF !important;
    border: 1px solid #CCCCCC !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 5px 5px 5px 15px !important;
}

.error-input {
    color: #EC1944 !important;
    font-size: .7rem !important;
    margin-left: 1rem;
    bottom: auto;
    display: inline-block;
    position: absolute;
    transition: all .25s linear !important;
    background-color: #fafafa;
    padding-left: 5px;
    border-radius: 5px;

    line-height: 1rem;
}

.btn-disable {
    opacity: 50%;
}

.hasTouchAction {
  touch-action: none;
}

.page-menu {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0060AE;
    position: absolute;
    /* bottom: 20px;
    right: 30px; */
    z-index: 2;
}

.page-menu > div {
    padding-right: 10px;
    padding-left: 10px;
}

.page-menu #dot-1, .page-menu #dot-2, .page-menu #dot-3 {
    background-color: #FFFFFF;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 21px;
    transition: all 0.15s linear;
}

.page-menu #dot-2 {
    right: 22px;
}

.page-menu #dot-3 {
    right: 10px;
}

.page-menu-active #dot-1 {
    width: 30px;
    height: 4px;
    top: 23px;
    border-radius: 5px;
    transform: rotate(45deg);
}

.page-menu-active #dot-2 {
    width: 0;
    height: 4px;
    top: 23px;
    border-radius: unset;
}

.page-menu-active #dot-3 {
    width: 30px;
    height: 4px;
    top: 23px;
    border-radius: 5px;
    transform: rotate(135deg);
}

.d-mobile {
    display: none;
}

.d-grid-mobile {

}

.d-flex-mobile {
    display: flex;
}

.name-column {
    color: #8493A8;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
}

.name-column-color {
    color: #8493A8;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.v-sheet.v-card {
    border-radius: 4px;
}

.theme--light.v-sheet {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.87);
}

.theme--light.v-card {
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.87);
}

.v-card {
    border-width: thin;
    display: block;
    max-width: 100%;
    outline: none;
    text-decoration: none;
    transition-property: box-shadow, opacity;
    overflow-wrap: break-word;
    position: relative;
    white-space: normal;
}

.v-sheet {
    border-radius: 0;
}

.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.v-card__title {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 16px;
}

.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
    color: rgba(0, 0, 0, 0.6);
}

.v-card__title + .v-card__subtitle {
    margin-top: -16px;
}

.v-card__title + .v-card__subtitle, .v-card__title + .v-card__text {
    padding-top: 0;
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 16px;
}

.v-card__subtitle, .v-card__text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}

.v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
    color: rgba(0, 0, 0, 0.6);
}

.v-card__subtitle + .v-card__text {
    padding-top: 0;
}

.v-card__text {
    width: 100%;
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 16px;
}

.v-card__subtitle, .v-card__text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}

.v-chip:not(.v-chip--outlined).primary, .v-chip:not(.v-chip--outlined).secondary, .v-chip:not(.v-chip--outlined).accent, .v-chip:not(.v-chip--outlined).success, .v-chip:not(.v-chip--outlined).error, .v-chip:not(.v-chip--outlined).warning, .v-chip:not(.v-chip--outlined).info {
    color: #FFFFFF;
}

.v-application .secondary {
    background-color: #37a423 !important;
    border-color: #37a423 !important;
}

.v-application .info {
    background-color: #00bcd4 !important;
    border-color: #00bcd4 !important;
}

.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 32px;
}

.v-chip {
    align-items: center;
    cursor: default;
    display: inline-flex;
    line-height: 20px;
    max-width: 100%;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-decoration: none;
    transition-duration: 0.28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    white-space: nowrap;
}

.v-divider {
    display: block;
    flex: 1 1 0px;
    max-width: 100%;
    height: 0px;
    max-height: 0px;
    border: solid;
    border-width: thin 0 0 0;
    transition: inherit;
}

.copy-icon-container {
    min-width: 20px;
    width: 20px;
    fill: #0060ae;
    padding: 2px;
    border: 1px solid rgb(0 96 174);
    border-radius: 4px;
}

.copy-icon-container:hover {
    background-color: #b7ddff;
}

#waiting-arrival-form td, #sent-parcel-form td {
    padding: 0;
}

#waiting-arrival-form .form-input:disabled, #sent-parcel-form .form-input:disabled {
    color: #000000!important;
}

.check-input input {
    border: 1px solid #C4C4C4!important;
    border-radius: 2px!important;
    box-shadow: none!important;
}

.width-1-14 {
    width: 7.14285%
}

.width-1-30 {
    width: 3.33333%
}
.p-relative {
    position: relative;
}
.p-absolute {
    position: absolute;
}

.fs-12px {
    font-size: 12px;
}

.fs-13px {
    font-size: 13px !important;
}

@media screen and (max-width: 1199px) {

    .btnWarePaySelected_container .btnWarePaySelected {
        height: 41.6px;
    }
    .mobile-version div {
        width: 49%;
    }
    .btnWarePaySelected {
        max-width: 100%;
    }
    .page-body {
        height: calc(100% - 150px);
    }

    main {
        padding-left: 0;
    }

    main[data-notificationbar=false] > div {
        padding: 10px;
        height: calc(100% - 104px);
    }

    main[data-footerisopen=true] > div {
        height: calc(100% - 138px);
    }

    main[data-footerisopen=true][data-notificationbar=true] > div {
        height: calc(100% - 206px);
    }

    main[data-footerisopen=false][data-notificationbar=true] > div {
        height: calc(100% - 166px);
    }

    .modal-form-fullscreen .modal-form-content {
        border-radius: unset;
        /* height: 100%; */
        width: 100%;
    }

    .modal-form-fullscreen {
        padding: 0;
    }

    .modal-form-body {
        height: calc(100% - 74px);
        /* max-height: 100%; */
    }

    .modal-form-body-feedback-small {
        height: calc(100% - 70px);
    }

    .modal-form-body-poll {
        height: calc(100% - 0px);
    }

    .modal-form-body form table {
        height: calc(100% - 151px);
        /* height: calc(100% - 204px); */
    }

    .modal-form-body form tr {
        display: grid;
    }

    .modal-form-body form tr td > div {
        border: unset;
        background-color: #FFFFFF;
    }

    .d-flex-mobile-none {
        display: none;
    }

    .d-mobile-none {
        display: none;
    }

    .d-mobile {
        display: unset;
    }

    .d-grid-mobile {
        display: grid;
    }

    .aside-large {
        left: -220px;
    }

    .nav-item {
        padding: 12px 10px 12px 20px;
    }

    .active-nav-item {
        background-color: #ECEFF4;
    }

    .active-nav-item:hover {
        background-color: #ECEFF4;
    }

    .btn-wh-copy {
        color: #0060AE !important;
    }

    .width-1-14 {
        width: 100%
    }
    .width-1-30 {
        width: 100%
    }

    .mobile-fs-11px-lh-15px-impot {
        font-size: 11px !important;
        line-height: 15px !important;
    }
}

@media screen and (min-width: 1200px) {

    .aside-small {
        left: -300px;
    }

    .sign-out-text {
        display: none;
    }

    .my-d-lg-flex {
        display: flex !important;
    }

    .my-d-lg-none {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    /*.sign-out-text {*/
    /*    display: none;*/
    /*}*/
    .p-md-1 {
        padding: 0.25rem;
    }
    .ml-md-5 {
        margin-left: 1.5rem;
    }
}

.btnWarePaySelected {
    text-align: center !important;
    padding: 4px 12px !important;
    background: transparent !important;
    color: #0d6efd !important;
    border-radius: 20px !important;
    /*font-size: 12px !important;*/
    line-height: 24px !important;
    font-weight: normal !important;
    text-transform: uppercase !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 8px !important;
    min-width: 100% !important;
    width: 220px;
}

.btnWarePaySelected:disabled {
    font-size: 14px;
    font-weight: bold;
}

.btnWarePaySelected:not(:disabled) {
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #0d6efd;
}

.btnWarePaySelected:not(:disabled) span {
    font-size: 14px;
    font-weight: bold;
}

.btnWarePaySelected:hover {
    background: transparent !important;
    border: 1px solid #0d6efd !important;
    color: #0d6efd !important;
}

.btnWarePaySelected:focus {
    box-shadow: unset !important;
}

.btnWarePaySelected:active {
    background: transparent !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

.btnWarePaySelected:disabled {
    background: #FFFFFF !important;
    border: 1px solid #C4C4C4 !important;
    color: #333333 !important;
}

.btnWarePaySelected.active {
    font-weight: bold !important;
    border: 1px solid #0d6efd !important;
    background: white !important;
    color:#0d6efd !important;
}
.btn-counter {
   background: #0d6efd;
   color: #FFFFFF;
   border-radius: 50%;
   min-width: 20px;
   height: 20px;
   padding: 0 6px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 11px;
   font-weight: 700;
}
.number-pay {
    background: #0d6efd;
    color: #FFFFFF;
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: -0.5rem !important;
}

.btn-select {
   font-size: 12px !important;
   padding: 4px 12px !important;
   border-radius: 20px !important;
   font-weight: normal !important;
}

.btn-select.active {
    border: 1px solid #0d6efd !important;
    color: #0d6efd !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}

.btn-select:disabled {
    border: 1px solid rgb(206, 212, 218) !important;
    color: rgb(206, 212, 218) !important;
}

@media screen and (max-width: 1199px) {
    .btnWarePaySelected,
    .btn-select {
        font-size: 11px !important;
        padding: 4px 8px !important;
    }

    .btn-counter {
        min-width: 18px;
        height: 18px;
        font-size: 10px;
    }
    .number-pay {
        width: 30px !important;
    }
    .form-control-static {
        padding: 0;
    }
}
@media (min-width: 1200px) {
    .form-control-static {
        padding: 8px 0;
    }
}
.full-width button {
    width: 100%;
}
.total-pay {
    background: #CAD3DFCC;
    border-radius: 30px;
    padding: 10px 10px 10px 20px !important;
    color: #126494;
    font-weight: 600;
    width: 50%;
}

.form-control-static {
    font-size: 16px;
    font-weight: 600;
}

.text-primary {
    color: #333;
    font-weight: 500;
}

.btn-success {
    background: #28a745;
    border: none;
}

.btn-light {
    background: #f8f9fa;
    border: 1px solid #ddd;
}

.btn-primary {
    background: #0056b3;
    border: none;
}
.mobile-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
.form-group-mobile {
    border-bottom: 1px solid black;
    padding: 15px;
    margin: 0 !important;
}

.payment-header {
    border-bottom: 1px solid black;
}
.mobile-form-actions {
    padding: 20px;
}
.title-label-mobile_form {
    color: #126494;
    font-weight: 600;
    font-size: 16px;
}
.title-label-mobile_form_lg {
    font-size: 20px;
    color: #126494;
    font-weight: 600;
    margin-bottom: 25px !important;
}
.btn-mobile-form {
    background: #478F51 !important;
    width: 45% !important;
    border-radius: 30px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px !important;
}
.btn-cancel {
    border-radius: 30px;
    background: #CAD3DFCC;
    color: #126494;
    font-weight: 600;
}
#mobile-modal {
    overflow-y: auto;
    padding: 20px 0 0 0;
}
.create-new-parcel-btn {
    /*margin-left: 10px;*/
    background-color: #EC1944;
    text-decoration: none;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid white;
    color: white;
    line-height: 16px;
    padding: 8px 8px !important;
}
.create-new-parcel-btn:hover {
    /*margin-left: 10px;*/
    background-color: #EC1944;
    text-decoration: none;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid white;
    color: white;
    line-height: 16px;
    padding: 8px 8px !important;
}
.create-new-parcel-btn_header {
    margin-left: 10px;
}
.create-new-parcel-btn svg {
    width: 15px;
    height: 15px;
    color: white;
    margin-left: 10px;
    margin-right: 5px;
    margin-top: -1px;
}
.create-new-parcel-btn span {
    width: 15px;
    height: 15px;
    color: white;
    padding: 0 5px;
}
.waiting_mobile {
    margin: 10px 0;
}
.sparcel_mobile {
    margin: 0;
}
.waiting-width {
    width: 194px;
    margin-bottom: 20px;
    margin-left: 0;
}
.mb-create-new-parcel-btn {
    width: 189px;
    /*margin: 0;*/
    margin-top: -5px;
}
.new-parcel-width {
    margin-left: -20px;
    width: 160px;
}
@media (min-width: 1200px) and (max-width: 1540px) {
    main[data-notificationbar=false] > div {
        height: calc(100% - 114px);
    }
    main[data-footerisopen=true] > div {
        height: calc(100% - 170px);
    }
}
@media (min-width: 1348px) and (max-width: 1442px) {
    .excel-template-import {
        max-width: 200px;
    }
}
@media (min-width: 1200px) and (max-width: 1347px) {
    .excel-template-import span {
        display: none;
    }
}
