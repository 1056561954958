.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  .popupTitleContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-blue);
    cursor: pointer;
    padding-right: 44px;
    .popupTitle {
      font-weight: 600;
      font-size: 22px;
      line-height: 1.22;
      color: var(--primary-black);
      margin: 0;
      padding: 0;
      width: 750px;
    
      @media screen and (max-width: 1170px) {
        min-width: 250px;
      }
    }
    
    @media screen and (max-width: 1170px) {
      flex-wrap: wrap;
    }
  }

  .notifsListItem {
    border-bottom: 2px solid #0060AE;
    padding: 0 5px;
    p {
      margin-bottom: 5px;
    }
    &:hover {
      background: #f5f5f5;
    }
  }

  @media screen and (max-width: 1170px) {
    gap: 16px;
  }
}