
/*----- Main colors -----*/

$col-my-outline-primary: #0D6EFD;
$col-my-text-primary: #0060AE;
$col-not-active-item: #ECEFF4;
$col-add: #37a423;
$col-highlight: #55C377;
$col-edit: #ffd61e;
$col-danger: #EC1944;
$col-grey-disable: #dfdfdf;

$col-warning: #e56a10;
$col-figma-primary: #0061AF;
$col-figma-accent: #E60033;
$col-figma-highlight: #55C377;
$col-figma-not-active-middle: #CAD3DF;
