.container {
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    z-index: 3;
    position: absolute;
    width: 540px;
    top: 45px;
    right: -5px;
    visibility: hidden;
    transform-origin: top;
    transition: visibility 200ms ease-in-out, transform 200ms ease-in;
    transform: rotate3d(1, 0, 0, 90deg);
}

.container[data-box-shadow="true"] {
    box-shadow: 0 1px 3px 1px rgba(10, 36, 50, 0.12);
}

.container[data-show="true"] {
  transform: rotate3d(0, 0, 0, 90deg);
  visibility: visible;
}

.arrow {
  position: absolute;
  top: 45px;
  right: 22px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  visibility: hidden;
  transform: rotate(45deg);
  transform-origin: 26px 26px;
  scale: 0;
  border-radius: 2px;
  z-index: 0;
  box-shadow: 0 1px 3px 1px rgba(10, 36, 50, 0.12);
  transition: visibility 200ms ease-in-out, scale 200ms ease-in;
}
.arrow[data-show="true"] {
  visibility: visible;
  scale: 1;
  transition: visibility 200ms ease-in-out, scale 200ms ease-in;
}

@media screen and (max-width: 1200px) {
  .container {
    position: fixed;
    width: calc(100% - 10px);
    min-width: 320px;
    max-width: 540px;
    padding: 24px;
    right: unset;
    left: 5px;
  }
}