
.classNameBtn {
    display: flex;
    align-items: center;
    padding: 0 !important;
    span {
        display: block;
        text-align: left;
        padding: 0 !important;
    }
}
