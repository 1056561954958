
.closeButton {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

@media screen and (max-width: 1170px) {
  .closeButton {
    right: 24px;
    top: 24px;
  }
}

.itemsContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
