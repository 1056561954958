*, ::after, ::before {
    box-sizing: border-box;
}

:root {
    --line-height: 24px;
    --font-size: 14px;
    --animate-duration: 400ms;
    --toastify-toast-min-height: 50px;
}
html{
    box-sizing: border-box;
    min-height: 100%;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    line-height: var(--line-height);
    font-size: var(--font-size);
    /*font-family: "Montserrat", sans-serif;*/
    background-color: #F9F9F9!important;
    min-height: 100%;
    overflow-x: hidden;
    /*background-color: #6875b2!important;*/
}

body * {
    font-family: "Montserrat", sans-serif;
}

main {
    padding-left: 220px;

    transition: padding-left 1s ease-in-out;
}

header {
    padding: 20px;
    box-shadow: 0px 1px 2px rgba(23, 23, 37, 0.12);
}

.aside-large {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 220px;
    transition-property: left;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
}

.aside-small {
    background-color: #FFFFFF;

    /*padding: 20px;*/
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 300px;
    transition-property: left;
    transition-duration: 650ms;
    transition-timing-function: ease-in-out;
}
